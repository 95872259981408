<template>
  <div class="card p-shadow-6">
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog />
    <h1>Necessidades de Formações</h1>
    <div class="p-col-12">
      <Button
        label="Adicionar Necessidade de Formação"
        icon="pi pi-plus"
        class="p-button-success p-button-outlined"
        @click="newTrainingNeed"
      ></Button>
    </div>
    <DataTable
      :value="trainingList"
      :filters.sync="filters"
      filterDisplay="row"
      :loading="loading"
      :paginator="true"
      :rows="20"
      class="p-datatable-sm"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50]"
      currentPageReportTemplate="Mostrar {first} ao {last} de {totalRecords}"
      sortField="name"
      :sortOrder="1"
      responsiveLayout="scroll"
    >
      <template #empty> Nada a mostrar. </template>
      <template #loading> A carregar. Por favor Aguarde... </template>
      <Column
        field="trainingName"
        header="Nome da Formação"
        sortable
        filterField="trainingName"
      >
        <template #body="slotProps">
          {{ slotProps.data.trainingName }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="creatorName"
        header="Criada por"
        sortable
        filterField="creatorName"
      >
        <template #body="slotProps">
          {{ slotProps.data.creatorName }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column field="accept" header="Estado" sortable filterField="accept">
        <template #body="slotProps">
          {{
            slotProps.data.accept == null
              ? "A aguardar"
              : slotProps.data.accept == 1
              ? "Aceite"
              : "Rejeitada"
          }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <Dropdown
            v-model="filterModel.value"
            @input="filterCallback()"
            :options="[
              { value: null, label: 'A aguardar' },
              { value: 0, label: 'Rejeitada' },
              { value: 1, label: 'Aceite' },
            ]"
            optionValue="value"
            optionLabel="label"
            class="p-column-filter"
            :showClear="true"
          >
          </Dropdown>
        </template>
      </Column>
      <Column>
        <template #body="slotProps">
          <Button
            v-if="
              slotProps.data.accept == null &&
              loggedUser.isEsa &&
              loggedUser.id == 128
            "
            tooltip.left="Converter para formação"
            icon="pi pi-check"
            class="p-button-rounded p-button-success p-button-outlined p-mr-2"
            @click="acceptReject(slotProps.data, 1)"
          />
          <Button
            v-if="
              slotProps.data.accept == null &&
              loggedUser.isEsa &&
              loggedUser.id == 128
            "
            tooltip.left="Rejeitar formação"
            icon="pi pi-times"
            class="p-button-rounded p-button-danger p-button-outlined p-mr-2"
            @click="acceptReject(slotProps.data, 0)"
          />
          <Button
            v-if="slotProps.data.accept == null"
            icon="pi pi-pencil"
            class="p-button-rounded p-button-success p-button-outlined p-mr-2"
            @click="edit(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>
    <Dialog
      :visible.sync="showForm"
      :style="{ width: $isMobile() ? '100vw' : '50vw', position: 'relative' }"
      :header="
        training.id == undefined
          ? 'Criar Necessidade de Formação'
          : 'Atualizar Necessidade de Formação'
      "
      :modal="true"
      :contentStyle="{ overflow: 'hide' }"
    >
      <form
        name="trainingForm"
        class="p-col-12"
        @submit.prevent="saveTrainingNeed"
        style="min-height: 50vh"
      >
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-12 p-mt-4">
            <span class="p-float-label">
              <InputText
                type="text"
                v-validate="'required'"
                v-model="training.trainingName"
                v-bind:class="[
                  { 'p-invalid': errors.has('trainingName') },
                  'form-control',
                ]"
                :name="'trainingName'"
              />
              <label for="trainingName">Nome</label>
            </span>
            <small
              v-if="errors.has('trainingName')"
              class="p-error"
              role="alert"
            >
              Nome é obrigatório
            </small>
          </div>
          <div
            class="p-field p-col-12 p-md-12 p-mt-4"
            v-if="loggedUser.isEsa || loggedUser.id == 128"
          >
            <span class="p-float-label">
              <Dropdown
                name="employees"
                :options="activeUsers"
                :filter="true"
                :optionLabel="'username'"
                :optionValue="'id'"
                v-model="training.creatorId"
                v-validate="'required'"
                v-bind:class="[
                  { 'p-invalid': errors.has('employees') },
                  'form-control',
                ]"
              >
              </Dropdown>
              <label for="inputtext">Selecionar Funcionário</label>
            </span>
            <small v-if="errors.has('employees')" class="p-error" role="alert">
              Funcionário é obrigatório
            </small>
          </div>
          <div class="p-field p-col-12 p-md-12 p-mt-4">
            <span class="p-float-label">
              <Textarea
                type="text"
                :autoResize="true"
                rows="5"
                v-validate="'required'"
                v-model="training.comments"
                v-bind:class="[
                  { 'p-invalid': errors.has('comments') },
                  'form-control',
                ]"
                :name="'comments'"
              />
              <label for="comments">Comentários</label>
            </span>
            <small v-if="errors.has('comments')" class="p-error" role="alert">
              Comentários é obrigatório
            </small>
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="saveTrainingNeed"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="closeForm"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api/";
import trainingService from "../services/training.service";
import employeeService from "../services/employee.service";
export default {
  name: "TrainingActions",
  data() {
    return {
      loading: true,
      trainingList: [],
      activeUsers: [],
      filters: {
        trainingName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        creatorName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        accept: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
      showForm: false,
      training: {},
    };
  },
  computed: {
    loggedUser() {
      return this.$store.state.auth.user;
    },
  },
  async created() {
    if (this.loggedUser.isEsa || this.loggedUser.id == 128) {
      await this.getActiveUsers();
    }
    await this.getTrainingsNeeds();
    this.loading = false;
  },
  methods: {
    getActiveUsers() {
      return employeeService
        .getList()
        .then((response) => (this.activeUsers = response));
    },
    getTrainingsNeeds() {
      return trainingService.getTrainingsNeeds().then((response) => {
        return (this.trainingList = response);
      });
    },
    getUserSearchLabel(info) {
      return `${info.username}`;
    },
    closeForm() {
      this.showForm = false;
      this.$validator.pause();
      this.$validator.reset();
      this.training = {};
    },
    edit(training) {
      this.training = training;
      this.showForm = true;
    },
    newTrainingNeed() {
      this.training = {};
      this.showForm = true;
    },
    saveTrainingNeed() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          return;
        }
        this.$validator.pause();
        this.$validator.reset();
        if (this.training.id == undefined) {
          return this.saveNewTraining();
        } else {
          return this.updateTraining();
        }
      });
    },
    saveNewTraining() {
      let bodyParms = {
        name: this.training.trainingName,
        comments: this.training.comments,
        userId:
          this.loggedUser.isEsa || this.loggedUser.id == 128
            ? this.training.creatorId
            : this.loggedUser.id,
      };

      return trainingService
        .createTrainingsNeeds(bodyParms)
        .then((response) => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro",
              detail: "Ocorreu um erro ao criar a formação",
              life: 3000,
            });
          }
          this.trainingList.push(response);
          this.$toast.add({
            severity: "success",
            summary: "Formação Criada",
            detail: "A formação foi criada com sucesso",
            life: 3000,
          });

          return this.closeForm();
        });
    },
    updateTraining() {
      let bodyParms = {
        name: this.training.trainingName,
        comments: this.training.comments,
      };

      return trainingService
        .updateTrainingsNeeds(this.training.id, bodyParms)
        .then((response) => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro",
              detail: "Ocorreu um erro ao atualizar a formação",
              life: 3000,
            });
          }
          this.$toast.add({
            severity: "success",
            summary: "Formação Atualizada",
            detail: "A formação foi atualizada com sucesso",
            life: 3000,
          });
          const areYou = (trainingAux) => {
            return trainingAux.id == this.training.id;
          };
          let trainigIndex = this.trainingList.findIndex(areYou);
          if (trainigIndex >= 0) {
            this.trainingList.splice(trainigIndex, 1);
            this.trainingList.push(response);
          }
          this.training = response;
          return this.closeForm();
        });
    },
    acceptReject(training, status) {
      let header = "";
      let message = "";
      if (status == 1) {
        header = `Aceitar Necessidade de Formação`;
        message = `Aceitar necessidade de Formação ${training.trainingName} e converter para formação?`;
      } else {
        header = `Rejeitar Necessidade de Formação`;
        message = `Rejeitar necessidade de Formação ${training.trainingName}?`;
      }
      this.$confirm.require({
        header: header,
        message: message,
        icon: "pi pi-question-circle",
        acceptLabel: "Sim",
        acceptIcon: "pi pi-check",
        acceptClass: "p-button-success p-button p-component",
        rejectLabel: "Não",
        rejectIcon: "pi pi-times",
        rejectClass: "p-button-danger p-button p-component",
        accept: () => {
          let bodyParms = {
            status: status,
          };
          return trainingService
            .accepRejetTrainingNeeds(training.id, bodyParms)
            .then((response) => {
              if (!response) {
                return this.$toast.add({
                  severity: "error",
                  summary: "Erro",
                  detail: "Ocorreu um erro ao atualizar a formação",
                  life: 3000,
                });
              }
              this.$toast.add({
                severity: "success",
                summary: "Formação Atualizada",
                detail: "A formação foi atualizada com sucesso",
                life: 3000,
              });
              const areYou = (trainingAux) => {
                return trainingAux.id == training.id;
              };
              let trainigIndex = this.trainingList.findIndex(areYou);
              if (trainigIndex >= 0) {
                this.trainingList.splice(trainigIndex, 1);
                this.trainingList.push(response);
              }
              this.training = response;
            });
        },
      });
    },
  },
};
</script>
<style>
.text-red {
  color: red;
}
</style>
